import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarWeekView, { AvailableHours, CalendarEvent } from "src/components/Calendar/CalendarWeekView";
import moment from 'moment';
import { Horse, Instructor, TrainingType } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { AvailabilitiesRequest, RiderTrainingsClient, Training } from 'src/api/stable/Booking';
import _ from 'lodash';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import Alert from 'src/components/Feedback/Alert';
import CalendarWeekController from 'src/components/Calendar/CalendarWeekController';
import useConfigurationState from 'src/hooks/useConfigurationState';
import BookTrainingHeader from './BookTrainingHeader';


interface Event {
  id: string;
  start: Date;
  end: Date;
}

export interface CalendarComponentProps {
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
  onSelect: (start: Date, end: Date) => void;
  onGoBack: () => void;
}

const Calendar = (props: CalendarComponentProps) => {
  const { type, instructor, horse, onSelect, onGoBack } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new RiderTrainingsClient(apiConfiguration);
  const configurationState = useConfigurationState();

  const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();
  const [startCalendar, setStartCalendar] = useState(moment().startOf('isoWeek').toDate());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [availabilities, setAvailabilities] = useState<Event[]>([]);

  const getAvailabilities = () => {
    setLoading(true);
    apiClient
      .availabilities({
        start: new Date(startCalendar),
        end: new Date(moment(startCalendar).add(7, 'days').subtract(1, 'second').toDate()),
        granularity: configurationState.booking.granularity ?? 30,
        training: {
          typeId: type.id || undefined,
          instructorId: instructor?.id || undefined,
          horseId: horse?.id || undefined
        } as Training
      } as AvailabilitiesRequest)
      .then(response => {
        setAvailabilities(
          _.values(response).map(day => {
            return day.map((item) => ({
              id: String(item.id),
              start: item.start!,
              end: item.end!,
              slots: item.availableSlots && item.allSlots ? [item.availableSlots, item.allSlots] : undefined
            })) || []
          }).flat()
        );
      })
      .catch(_ => setError(true))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAvailabilities();
  }, [startCalendar]);

  const ownEvents = [
    { id: "own", start: new Date(2024, 2, 21, 13, 0, 0), end: new Date(2024, 2, 21, 14, 0, 0) }
  ]
  const selectedEvents = selectedStartDate ? [{ id: "select", start: selectedStartDate, end: selectedEndDate!, label: t('ui.calendar.currentChoice'), colorName: "emerald", active: true }] : [];

  const calendarEvents: CalendarEvent[] = [
    ...ownEvents.map(event => ({ ...event, label: t('stable.trainings.myTraining'), colorName: "red", disabled: true })),
    ...selectedEvents
  ];

  const hours: AvailableHours[] = _.map(
    configurationState?.booking?.hours,
    (v, k) => ({ key: Number(k), start: [Number(v.start[0]), Number(v.start[1])], end: [Number(v.end[0]), Number(v.end[1])] })
  )
    .sort((a, b) => a.key - b.key)
    .map(v => ({ start: v.start, end: v.end }));

  const areDateRangesConflicted = (firstStart: Date, firstEnd: Date, secondStart: Date, secondEnd: Date) => {
    secondEnd.setSeconds(secondEnd.getSeconds() - 1);
    return (firstStart <= secondEnd && secondStart <= firstEnd) ||
      (secondStart <= firstEnd && firstStart <= secondEnd);
  }

  const onSubmit = () => {
    if (selectedStartDate && selectedEndDate) {
      onSelect(selectedStartDate, selectedEndDate);
    }
  }

  const isDateRangeAvailable = (start: Date, end: Date) => {
    end.setSeconds(end.getSeconds() - 1);
    const eventsInConflict = calendarEvents.filter(f => f.id !== 'select').some(e => areDateRangesConflicted(start, end, e.start, e.end));
    const weekDay = start.getDay();
    const todayHours = hours[weekDay];
    if (!todayHours) {
      return false;
    }
    const todayHoursCorrected = {
      start: todayHours.start[0],
      end: todayHours.end[1] != 0 ? (todayHours.end[0] + 1) : todayHours.end[0]
    }
    const isInHoursDown = start.getHours() >= todayHoursCorrected.start;
    const isInHoursUp = end.getHours() <= todayHoursCorrected.end;
    return !eventsInConflict && isInHoursDown && isInHoursUp;
  }

  useEffect(() => {
    if (!selectedStartDate) return;
    if (!isDateRangeAvailable(selectedStartDate, selectedEndDate!)) {
      let canCorrect = false;
      for (let i = 1; i <= 3; i++) {
        const newDate = moment(selectedStartDate).subtract(15 * i, 'minutes').toDate();
        const newEndDate = moment(newDate).add(type.duration, 'minutes').toDate();
        if (isDateRangeAvailable(newDate, newEndDate)) {
          setSelectedStartDate(newDate);
          setSelectedEndDate(newEndDate);
          canCorrect = true;
          break;
        }
      }
      if (!canCorrect) {
        setSelectedStartDate(undefined);
        setSelectedEndDate(undefined);
      }
    }
  }, [selectedStartDate]);

  useEffect(() => {
    getAvailabilities();
  }, [startCalendar]);

  const onClickTerm = (start: Date, end: Date) => {
    setSelectedStartDate(start);
    setSelectedEndDate(end);
  }

  const onClickEvent = (event: Event) => {
    if (event.id === 'select') {
      setSelectedStartDate(undefined);
      setSelectedEndDate(undefined);
    }
    else {
      setSelectedStartDate(event.start);
      setSelectedEndDate(event.end);
    }
  }

  return (
    <>
      <div className="max-w-sm md:max-w-full px-4 md:px-8 mx-auto mb-8">
        <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={!selectedStartDate || !selectedEndDate || loading} >
          {t('stable.trainings.booking.selectTrainingDate')}
        </BookTrainingHeader>
        <div className="border-y border-y-gray-200 py-5 my-5">
          {error && <Alert.Error title={t('common.status.error')} noClose >{t('stable.trainings.errors.cantGetUnavailabilities')}</Alert.Error>}
          {!error && <div className="flex flex-col md:flex-row gap-y-5 items-center lg:justify-between">
            <div className="flex flex-col md:flex-row gap-5">
              <div className="font-medium">Legenda:</div>
              <div>
                <div className="inline-block size-4 bg-red-50 relative top-1"></div> - termin niedostępny
              </div>
              <div>
                <div className="inline-block size-4 bg-emerald-200 ring-emerald-50 relative top-1"></div> - termin dostępny
              </div>
              <div>
                <div className="inline-block size-4 bg-sky-100 ring-sky-50 relative top-1"></div> - wolne miejsca na treningu grupowym
              </div>
            </div>
            <div className="mx-auto justify-center lg:mr-0">
              <div className="relative items-center rounded-md bg-white shadow-sm md:items-stretch hidden md:flex">
                <CalendarWeekController currentDate={startCalendar} onChangeDate={setStartCalendar} />
              </div>
            </div>
          </div>}
          <div className="my-5">
            <div className="relative">
              {loading && <LoadingOverlay />}
              <CalendarWeekView
                startCalendar={startCalendar}
                setStartCalendar={setStartCalendar}
                startHour={_.min(hours.map(h => h.start[0]).filter(f => f != 0)) ?? 8}
                endHour={_.max(hours.map(h => h.end[1] ? h.end[0] + 1 : h.end[0]).filter(f => f != 0)) ?? 16}
                events={calendarEvents}
                availableTerms={availabilities}
                onClickEvent={onClickEvent}
                onClickTerm={onClickTerm}
                availableHours={hours}
                isControllable
              />
            </div>
          </div>
        </div>
        <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={!selectedStartDate || !selectedEndDate || loading} />
      </div >
    </>
  )
}

export default Calendar;